import { AxiosError } from 'axios';
import { Form, Formik, getIn, useFormikContext } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';

import { Icons } from '../../../../assets';
import { useMutationUser, useQueryWallet } from '../../../../hooks';
import { COLORS, FONTS } from '../../../../theme';
import { IAuthError, INewWallet } from '../../../../types';
import { Button, Input, Loader } from '../../../common/component';
import { DropDownlist } from '../../../common/component/drop-down-list/drop-downlist';
import { PopupLayout } from '../../../common/component/popup-layout/popup-layout';
import { Portal } from '../../../common/component/portal';
import { IconCommon, TitleCommon } from '../../../common/styles';
import { validationChangePassword } from '../../validation/shema';
import { ChangePassworWrapper } from './changePassword.styled';


interface IChangePassword {
  user: any;
}


interface IChangePasswordIndex {
  user: any;
  onClose: () => void;
}

const ChangePasswordIndex = ({ user, onClose}: IChangePasswordIndex) => {
  const { isValid, values, setFieldError } = useFormikContext();
 

  const { isLoading, mutate: changeUserPassword } = useMutationUser.post({
    options: {
      addOnSuccess: () => {
        onClose();
      },
      addOnError: (err: any) => {
        if (err.errorCode === 120) {
          setFieldError('name', err.message);
        }
        if ([121, 122].includes(err.errorCode)) {
          setFieldError('address', err.message);
        }
      }
    }
  });

  const onChangePassword = (data: any) => {
    changeUserPassword(data);
  };

  return (
    <>
      <Portal>
        <PopupLayout onClose={onClose}>
          <TitleCommon>Change Password</TitleCommon>

          <ChangePassworWrapper>
            <Input name='email' placeholder='Email' width='25rem' height='2.9375rem' label='Email' readOnly/>
            <Input name='password' placeholder='Password' width='25rem' height='2.9375rem' label='Password' />
            <Input name='newPassword' placeholder='New Password' width='25rem' height='2.9375rem' label='New Password' />     

            <Button
              content={
                !isLoading ? (
                  'Submit'
                ) : (
                  <Loader size='small' color={COLORS.green} height='auto' />
                )
              }
              width='25rem'
              height='2.9375rem'
              mt='1.125rem'
              mb='2.125rem'
              color={COLORS.white}
              type='button'
              variant='primaryGreen'
              disabled={isLoading || !isValid}
              onClick={onChangePassword.bind(this, values as any)}
            />
          </ChangePassworWrapper>
        </PopupLayout>
      </Portal>
    </>
  );
};

export const ChangePassword = ({user}: IChangePassword) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSetIsOpen = (flag: boolean) => {
    setIsOpen(flag);
  };

  return (
    <>
      <IconCommon
        icon={Icons.lock}
        background={COLORS.black}
        height={FONTS.SIZES.l}
        cursor='pointer'
        title='Change password'
        onClick={onSetIsOpen.bind(this, true)}
      />

      {isOpen && (
        <Formik
          initialValues={{
            email: user?.user?.email ?? " ",
            password: '',
            newPassword: ''
          }}
          onSubmit={() => {}}
          validationSchema={validationChangePassword}
          enableReinitialize
        >
          {() => (
            <Form>
              <ChangePasswordIndex onClose={onSetIsOpen.bind(this, false)} user={user}/>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
