export const passwordError: string[] = [
  'LOWERCASE LETTER',
  'CAPITAL LETTER',
  'SPECIAL CHATACTER',
  '8 CHARACTERS'
];

export const InputsConst: Record<
  | 'first_name'
  | 'last_name'
  | 'nick_name'
  | 'email'
  | 'admin_user_info'
  | 'phone'
  | 'password'
  | 'current_password'
  | 'newPassword'
  | 'confirm_password'
  | 'company_info'
  | 'location'
  | 'city'
  | 'state'
  | 'zip'
  | 'country'
  | 'country_other'
  | 'category'
  | 'category_other'
  | 'role'
  | 'description'
  | 'manufacturer_mame'
  | 'manufacturer_mame_other'
  | 'files'
  | 'model_number'
  | 'spare_parts'
  | 'name',
  {
    name: string;
    label: string;
    placeholder: string;
  }
> = {
  first_name: {
    name: 'first_name',
    label: 'First Name',
    placeholder: 'First Name'
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
    placeholder: 'Last Name'
  },
  nick_name: {
    name: 'nick_name',
    label: 'Nickname',
    placeholder: 'Nickname'
  },
  email: {
    name: 'email',
    label: 'Email',
    placeholder: 'Email Address'
  },
  admin_user_info: {
    name: 'admin_user_info',
    label: 'Email',
    placeholder: 'Email Address'
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    placeholder: 'Phone'
  },
  password: {
    name: 'password',
    label: 'Password',
    placeholder: 'Your Password'
  },
  current_password: {
    name: 'current_password',
    label: 'Current Password',
    placeholder: 'Current Password'
  },
  newPassword: {
    name: 'newPassword',
    label: 'New Password',
    placeholder: 'New Password'
  },
  confirm_password: {
    name: 'confirm_password',
    label: 'Confirm Password',
    placeholder: 'Confirm Password'
  },
  company_info: {
    name: 'company_info',
    label: 'Company Name',
    placeholder: 'Company Name'
  },
  location: {
    name: 'location',
    label: 'Location',
    placeholder: 'Choose Location'
  },
  files: {
    name: 'files',
    label: 'File',
    placeholder: 'Choose File'
  },
  city: {
    name: 'city',
    label: 'City',
    placeholder: 'Choose City'
  },
  state: {
    name: 'state',
    label: 'State',
    placeholder: 'Choose State'
  },
  zip: {
    name: 'zip',
    label: 'Zip',
    placeholder: 'Choose Zip'
  },
  category: {
    name: 'category',
    label: 'Select Category',
    placeholder: 'Select Category'
  },

  country: {
    name: 'country',
    label: 'Select Country',
    placeholder: 'Select Country'
  },
  country_other: {
    name: 'country_other',
    label: 'Select Country Other',
    placeholder: 'Select Country Other'
  },
  category_other: {
    name: 'category_other',
    label: 'Select Category Other',
    placeholder: 'Select Category Other'
  },

  role: {
    name: 'role',
    label: 'User Permission',
    placeholder: 'User Permission'
  },
  description: {
    name: 'description',
    label: 'Description',
    placeholder: 'Description / Additional Information'
  },
  manufacturer_mame: {
    name: 'manufacturer_mame',
    label: 'Manufacturer Name',
    placeholder: 'Manufacturer Name'
  },
  manufacturer_mame_other: {
    name: 'manufacturer_mame_other',
    label: 'Manufacturer Name Other',
    placeholder: 'Manufacturer Other'
  },
  model_number: {
    name: 'model_number',
    label: 'Model Number',
    placeholder: 'Model Number'
  },
  spare_parts: {
    name: 'spare_parts',
    label: 'Spare Parts and Accessories Information',
    placeholder: 'Spare Parts and Accessories Information'
  },
  name: {
    name: 'name',
    label: 'Item Title',
    placeholder: 'Item Title'
  }
};

export const commonInformation: {
  name: keyof typeof InputsConst;
  type: string;
}[] = [
  { name: 'first_name', type: 'text' },
  { name: 'last_name', type: 'text' },
  { name: 'email', type: 'email' }
];
