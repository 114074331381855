export const Icons = {
  error: require('./error-icon.svg').default,
  success: require('./success-icon.svg').default,
  closeIcon: require('./close-icon.svg').default,
  arrowBottomIcon: require('./arrow-bottom.svg').default,
  plusIcon: require('./plus.svg').default,
  logout: require('./logout.svg').default,
  edit: require('./edit.svg').default,
  editActive: require('./edit-active.svg').default,
  basket: require('./basket.svg').default,
  confirm: require('./confirm.svg').default,
  cancel: require('./cancel.svg').default,
  arrowLeft: require('./arrow-left.svg').default,
  arrowRight: require('./arrow-right.svg').default,
  search: require('./search.svg').default,
  lock: require('./lock.svg').default
  
};
