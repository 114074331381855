import { AxiosError } from 'axios';
import {useMutation, useQueryClient} from 'react-query';
import { useNavigate } from 'react-router-dom';

import { APP_KEYS } from '../../module/common/constants';
import { onError, onSuccess } from '../../module/common/services';
import { IMessage } from '../../module/common/types';
import { userService } from '../../services/user/user.service';
import { IAuthError, IChangePassword } from '../../types';

interface IPost {
  options?: {
    addOnSuccess?: () => void;
    addOnError?: (err: AxiosError<IAuthError>) => void;
  };
}

const logout = () => {
  const navigate = useNavigate();
  const client = useQueryClient()

  return useMutation<any, AxiosError<IAuthError>, any>(async () => await userService.logout(), {
    onSuccess: (data: IMessage) => {
      onSuccess({ message: data.message });

      localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
      localStorage.removeItem(APP_KEYS.STORAGE_KEYS.REFRESH_TOKEN);

      client.clear();
      navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
    },
    onError: (err: AxiosError<IAuthError>) => onError(err)
  });
};

const post = ({ options }: IPost) => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IChangePassword>(
    async (data: IChangePassword) => await userService.changePassword(data),
    {
      onSuccess: (data: IMessage) => {
        // client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_LIST]);
        onSuccess({ message: data.message });
        options?.addOnSuccess && options?.addOnSuccess();
      },
      onError: (err: AxiosError<IAuthError>) => {
        onError(err);
        options?.addOnError && options.addOnError(err);
      }
    }
  );
};

export const useMutationUser = {
  logout,
  post
};
